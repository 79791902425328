import * as React from "react";
import {useState} from "react";

import useIsClient from "../../hooks/use-is-client";
import {useFenixContext} from "../../helpers/context";
import ServiceTabItem from "./service-tab-item";
import ServiceTabContent from "./service-tab-content";
import CleanDataParser from "../../helpers/editorjs-parser";

export default function ServiceTabs(props: any) {
  const [activeTab, setActiveTab] = useState("tab1");
  const {state, dispatch} = useFenixContext();
  const {isClient, key} = useIsClient();

  function handleChange(e: any) {
    setActiveTab("tab" + e.target.selectedIndex);
  }

  return (
    <div className="block-wrapper pr-4 pr-md-0 pl-4 pl-md-0">
      <div className="service-title text-white mb-3 pl-0 pl-md-5 pt-2">
        <h2>{props.includedServicesTitle}</h2>
      </div>
      <div className="services-tabs">
        <div className="d-none d-xl-flex services-tabs__nav">
          {props.includedServices.map((item: any, index: any) => (
            <ServiceTabItem key={index} title={item.title} id={"tab" + index} activeTab={activeTab}
                            setActiveTab={setActiveTab}/>
          ))}
        </div>
        {isClient && <select
          name="services-select"
          className="d-xl-none services-select"
          onChange={handleChange}
          defaultValue={props.includedServices[0]?.title}
        >
          {props.includedServices.map((item: any, index: any) => (
            <option key={index} id={"tab" + index} value={item.title}
                    dangerouslySetInnerHTML={{__html: item.title}}></option>
          ))
          }
        </select>}
        <div className="services-tabs__content text-white pt-4 pb-5">
          {props.includedServices.map(function (item, i) {
            const singleTabText = (
              <div>
                {item.text?.data?.text ? (
                  JSON.parse(item.text?.data?.text).blocks.map(
                    (block: any, idx: any) => CleanDataParser(block, idx)
                  )
                ) : (
                  <div/>
                )}
              </div>
            );
            return <ServiceTabContent key={i} id={"tab" + i} activeTab={activeTab}>
              <div className="col-0 col-lg-4"></div>
              <div className="col-12 col-lg-5">
                <h3 className="" dangerouslySetInnerHTML={{__html: item.title}}></h3>
                <div className="pt-4">{singleTabText}</div>
                <button
                  onClick={() => dispatch({type: "modalBooking"})}
                  data-toggle="modal"
                  data-target="#bookMeetingForm"
                  className={props.serviceClass === "legal" ? "btn bg-darkyellow tab-button tab-button--yellow text-white gtm-strongintent" : "btn bg-darkblue tab-button text-white gtm-strongintent"}
                  title="Book consultation">Contact us
                </button>
              </div>
              <div className="col-0 col-lg-3"></div>
            </ServiceTabContent>
          })}
        </div>
      </div>
    </div>
  );
};