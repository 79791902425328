import * as React from "react";

export default function ServiceTabItem({id, title, activeTab, setActiveTab}) {

  const handleClick = () => {
    setActiveTab(id);
  };

  return (
    <div onClick={handleClick}
         className={activeTab === id ? "services-tabs__item d-flex justify-content-center align-items-center text-center active bg-white text-deepblue" : "services-tabs__item d-flex justify-content-center align-items-center text-center text-white"}
         dangerouslySetInnerHTML={{__html: title}}></div>
  );
};